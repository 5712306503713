var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-row",
    [
      _c(
        "v-col",
        { attrs: { cols: "12", sm: "12" } },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-toolbar",
                    {
                      staticClass: "transparent mx-n3",
                      attrs: { dense: "", flat: "", height: "40px" }
                    },
                    [
                      _c(
                        "v-toolbar-title",
                        {
                          staticClass:
                            "mr-3 text-subtitle-1 font-weight-regular"
                        },
                        [_vm._v("Applications")]
                      ),
                      _c(
                        "v-chip",
                        {
                          staticClass: "secondary text-caption",
                          attrs: { small: "", dark: "" }
                        },
                        [_vm._v(_vm._s(_vm.uploads.length))]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { small: "", icon: "", title: "Grid View" },
                          on: {
                            click: function($event) {
                              _vm.activeView = "grid"
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "btn-views",
                              class: {
                                "btn-views-active": _vm.activeView === "grid"
                              },
                              attrs: { medium: "", color: "primary" }
                            },
                            [_vm._v("mdi-grid")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { small: "", icon: "", title: "List View" },
                          on: {
                            click: function($event) {
                              _vm.activeView = "list"
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "btn-views",
                              class: {
                                "btn-views-active": _vm.activeView === "list"
                              },
                              attrs: { medium: "", color: "secondary" }
                            },
                            [_vm._v("mdi-view-list")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("v-divider", { staticClass: "ml-4 mr-2" }),
                  _c(
                    "v-row",
                    [
                      _vm._l(_vm.uploads, function(upload) {
                        return [
                          _c(
                            "v-col",
                            {
                              key: upload.id,
                              attrs: { cols: "12", md: "6", lg: "4" }
                            },
                            [
                              _c("psi-detail-card", {
                                attrs: {
                                  icon: "mdi-file",
                                  "heading-title": upload.file.name,
                                  items: upload.items,
                                  itemNew: upload.itemNew,
                                  "heading-subtitle":
                                    "Created " + upload.created_at
                                }
                              })
                            ],
                            1
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }