<template>
    <v-row>
        <v-col cols="12" sm="12">
            <v-card>
                <v-card-text>
                    <v-toolbar dense flat class="transparent mx-n3" height="40px">
                        <v-toolbar-title
                            class="mr-3 text-subtitle-1 font-weight-regular"
                        >Applications</v-toolbar-title>
                        <v-chip small class="secondary text-caption" dark>{{uploads.length}}</v-chip>
                        <!-- <v-btn small color="secondary" title="Filter results" class="ml-10">
                        <v-icon>mdi-filter</v-icon>
                        </v-btn>-->
                        <v-spacer></v-spacer>

                        <v-btn small icon title="Grid View" @click="activeView = 'grid'">
                            <v-icon
                                medium
                                color="primary"
                                class="btn-views"
                                :class="{ 'btn-views-active': activeView === 'grid'}"
                            >mdi-grid</v-icon>
                        </v-btn>
                        <v-btn small icon title="List View" @click="activeView = 'list'">
                            <v-icon
                                medium
                                color="secondary"
                                class="btn-views"
                                :class="{ 'btn-views-active': activeView === 'list'}"
                            >mdi-view-list</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-divider class="ml-4 mr-2"></v-divider>

                    <v-row>
                        <template v-for="upload in uploads">
                            <v-col cols="12" md="6" lg="4" :key="upload.id">
                                <psi-detail-card
                                    icon="mdi-file"
                                    :heading-title="upload.file.name"
                                    :items="upload.items"
                                    :itemNew="upload.itemNew"
                                    :heading-subtitle="`Created ${upload.created_at}`"
                                ></psi-detail-card>
                            </v-col>
                        </template>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "application-uploads-applications",
    components: {},
    props: {},
    data() {
        return {
            activeView: "grid",
            menuItems: [
                {
                    title: "Assign Processor",
                    icon: "mdi mdi-account-check",
                },
                {
                    title: "Download File",
                    icon: "mdi mdi-file-download",
                },
                {
                    title: "Review Extract",
                    icon: "mdi mdi-file-find",
                },
            ],
        };
    },
    computed: {
        ...mapGetters("Uploads", ["uploads"]),
        // Format the uploaded data for a grid detailed row display
    },
    watch: {},
    created() {},
    mounted() {
        this.getUploads(10);
    },
    updated() {},
    detroyed() {},
    methods: {
        ...mapActions("Uploads", ["getUploads"]),
    },
};
</script>

<style scoped>
.btn-views {
    opacity: 0.5;
}
.btn-views-active {
    opacity: 1;
}
</style>